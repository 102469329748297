<app-engine #engine 
    (simulatorReady)="onSimulatorReady()"
    (simulationLoaded)="onSimulationLoaded($event)"
    (rendererReady)="onRendererReady()"
    (exitRenderer)="onExitRenderer($event)"
    (pointerLockChanged)="onPointerLockChanged($event)"
></app-engine>
<button id="enterAR" mat-raised-button class="!fixed !w-24 !h-auto !bottom-32 !right-12"
    [ngClass]="{'!hidden': !hasAR}"
>
    <img class="" src="assets/images/icons/buttons/ar.png"/>
</button>

<button id="enterVR" mat-raised-button class="!fixed !w-24 !h-auto !bottom-12 !right-12"
    [ngClass]="{'!hidden': !hasVR}"
>
    <img class="" src="assets/images/icons/buttons/vr.png"/>
</button>
