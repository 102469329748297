import { Routes } from '@angular/router';

import { authGuard, unAuthedGuard } from './auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/public/about',
    pathMatch: 'full'
  },
  {
    path: 'about',
    redirectTo: '/public/about',
    pathMatch: 'full'
  },
  {
    path: 'products',
    redirectTo: '/public/products',
    pathMatch: 'full'
  },
  {
    path: 'for-therapists',
    redirectTo: '/public/for-therapists',
    pathMatch: 'full'
  },
  {
    path: 'recovery-stories',
    redirectTo: '/public/recovery-stories',
    pathMatch: 'full'
  },
  {
    path: 'our-story',
    redirectTo: '/public/our-story',
    pathMatch: 'full'
  },
  {
    path: 'dementia',
    redirectTo: '/public/dementia',
    pathMatch: 'full'
  },
  {
    path: 'resources',
    redirectTo: '/public/resources',
    pathMatch: 'full'
  },
  {
    path: 'browser-extension-guide',
    redirectTo: '/public/browser-extension-guide',
    pathMatch: 'full'
  },
  {
    path: 'contact-us',
    redirectTo: '/public/contact-us',
    pathMatch: 'full'
  },
  {
    path: 'privacy-policy',
    redirectTo: '/public/privacy-policy',
    pathMatch: 'full'
  },
  // {
  //   path: 'shop',
  //   redirectTo: '/public/shop',
  //   pathMatch: 'full'
  // },
  {
    path: 'public',
    loadComponent: () => import('./pages/public-site/public-site.component').then(mod => mod.PublicSiteComponent),
    children: [
      {
        path: '',
        redirectTo: 'about',
        pathMatch: 'full'
      },
      {
        path: 'about',
        loadComponent: () => import('./pages/about/about.component').then(mod => mod.AboutComponent)
      },
      {
        path: 'products',
        loadComponent: () => import('./pages/products/products.component').then(mod => mod.ProductsComponent)
      },
      {
        path: 'for-therapists',
        loadComponent: () => import('./pages/for-therapists/for-therapists.component').then(mod => mod.ForTherapistsComponent)
      },
      {
        path: 'recovery-stories',
        loadComponent: () => import('./pages/recovery-stories/recovery-stories.component').then(mod => mod.RecoveryStoriesComponent)
      },
      {
        path: 'our-story',
        loadComponent: () => import('./pages/our-story/our-story.component').then(mod => mod.OurStoryComponent)
      },
      {
        path: 'dementia',
        loadComponent: () => import('./pages/dementia/dementia.component').then(mod => mod.DementiaComponent)
      },
      {
        path: 'resources',
        loadComponent: () => import('./pages/resources/resources.component').then(mod => mod.ResourcesComponent)
      },
      {
        path: 'browser-extension-guide',
        loadComponent: () => import('./pages/browser-extension-guide/browser-extension-guide.component').then(mod => mod.BrowserExtensionGuideComponent)
      },
      {
        path: 'contact-us',
        loadComponent: () => import('./pages/contact-us/contact-us.component').then(mod => mod.ContactUsComponent)
      },
      {
        path: 'privacy-policy',
        loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(mod => mod.PrivacyPolicyComponent)
      },
      // {
      //   path: 'shop',
      //   loadComponent: () => import('./pages/shop/shop.component').then(mod => mod.ShopComponent)
      // }
    ]
  },

  {
    path: 'auth',
    loadComponent: () => import('./auth/components/auth/auth.component').then(mod => mod.AuthComponent),
    canActivate: [unAuthedGuard],
    children: [
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
      },
      {
        path: 'action',
        loadComponent: () => import('./auth/components/action/action.component').then(mod => mod.ActionComponent)
      },
      {
        path: 'sign-in',
        loadComponent: () => import('./auth/components/register-sign-in/register-sign-in.component').then(mod => mod.RegisterSignInComponent)
      },
      {
        path: 'verify-email',
        loadComponent: () => import('./auth/components/verify-email/verify-email.component').then(mod => mod.VerifyEmailComponent)
      },
      {
        path: 'forgot-password',
        loadComponent: () => import('./auth/components/forgot-password/forgot-password.component').then(mod => mod.ForgotPasswordComponent)
      },
    ]
  },

  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard/components/dashboard/dashboard.component').then(mod => mod.DashboardComponent),
    canActivate: [authGuard],
    children: [
      // {
      //   path: 'user/:id',
      //   outlet: 'toolbar',
      //   loadComponent: () => import('./dashboard/components/user-toolbar/user-toolbar.component').then(mod => mod.UserToolbarComponent)
      // },
      {
        path: 'user/:id',
        outlet: 'banner',
        loadComponent: () => import('./dashboard/components/patient-banner/patient-banner.component').then(mod => mod.PatientBannerComponent)
      },
      {
        path: 'games/:id',
        outlet: 'content',
        loadComponent: () => import('./dashboard/components/engine-host/engine-host.component').then(mod => mod.EngineHostComponent)
      },
      {
        path: 'programs/:id',
        outlet: 'content',
        loadComponent: () => import('./dashboard/components/programs/programs.component').then(mod => mod.ProgramsComponent)
      },
      {
        path: 'reports/:id',
        outlet: 'content',
        loadComponent: () => import('./dashboard/components/reports/reports.component').then(mod => mod.ReportsComponent)
      },
      {
        path: 'settings/:id',
        outlet: 'content',
        loadComponent: () => import('./dashboard/components/settings-extension/settings-extension.component').then(mod => mod.SettingsExtensionComponent)
      }
    ]
  },


  

];

