import { AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { User } from '@angular/fire/auth';

import { Subscription} from 'rxjs';

import { EngineManagerComponent } from './game-engine/components/engine-manager/engine-manager.component';

import { AuthService } from './auth/services/auth.service';
import { UserService } from './auth/services/user.service';

@Component({
  selector: 'ablex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [EngineManagerComponent, NgClass, RouterOutlet]
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  
  public isGame = false;
  public isLoggedIn = false;
  public user: User | null = null;

  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);

  private _authStateSubscription!: Subscription;
  private _userProfileSubscription!: Subscription | undefined;

  // private analytics: Analytics = inject(Analytics);

  @ViewChild('engineManager') engineManager!:EngineManagerComponent;

  ngOnInit(): void {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/service-worker.js').then(registration => {
    //     console.log('SW registered: ', registration);
    //   }).catch(registrationError => {
    //     console.log('SW registration failed: ', registrationError);
    //   });
    // }
  }

  ngAfterViewInit(){
    // Toggle pointer-event css mode based on if in a game or not
    // Sometimes we want the engine just for as a display overlay that cant be interacted with eg promo scene 
    this.engineManager.navigationEndWithGamesSegment.subscribe(value => { this.isGame = value; });
    this.engineManager.rendererReady.subscribe(()=>this.onRendererReady())
  }

  onRendererReady(){
    this.loadUserProfile();
  }

  private loadUserProfile(){
    this._authStateSubscription = this.authService.authState$.subscribe((user: User | null) => {
      this.user = user;
      this.isLoggedIn = user ? true : false;

      if(user){
        this._userProfileSubscription = this.userService.getUserProfileObservable(user.uid)?.subscribe(userProfileUpdate => {

          const userProfile = userProfileUpdate.userProfile;

          const controllerData = {
            enableDwellClick: userProfile.extension_settings?.enableDwellClick === true ? true : false,
            enableLeftHanded: userProfile.extension_settings?.enableLeftHanded === true ? true : false,
            dwellTime: userProfile.extension_settings?.dwellTime !== undefined ? userProfile.extension_settings.dwellTime : 2,
            scale: userProfile.extension_settings?.scale !== undefined ? userProfile.extension_settings.scale : 1,
            sensitivity: userProfile.extension_settings?.sensitivity !== undefined ? userProfile.extension_settings.sensitivity : 100
          }

          this.engineManager.engine.setControllerOptions(controllerData);
        })
      } else {
        this._userProfileSubscription?.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    this._authStateSubscription?.unsubscribe();
    this._userProfileSubscription?.unsubscribe();
  }
}
