<!-- 3D content -->
<ablex-engine-manager #engineManager 
  class="fixed top-0 w-full h-full z-50 opacity-100" 
  [ngClass]="isGame ? 'pointer-events-auto' : 'pointer-events-none'">
</ablex-engine-manager>

<!-- 2D DOM content -->
<div class="block w-full h-full">
  <router-outlet></router-outlet>
</div>

<!-- flex flex-col -->