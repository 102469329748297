import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';
 
export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(this.unescape(url));
  }
 
  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    return dus.serialize(tree);
  }

  unescape(url: string): string{
    // Firebase encodes the url before forwarding it to our app
    // however angular routing doesnt work with encoded urls
    // So we replace the encoding for the ( and ) with the characters again
    // Also seems the multitple // in nested outlets gets turned into a single /
    // which again breaks routing. No good way to fix this, and 
    // We have just one of these so turning all /content into //content
    
    // If we have an outlet name preceeded by a single / convert to //
    if(url.includes("/content") && !url.includes("//content")){
      url = url.replaceAll('/content','//content');
    }

    if(url.includes("/banner") && !url.includes("//banner")){
      url = url.replaceAll('/banner','//banner');
    }

    return url.replaceAll('%28','(').replaceAll('%29',')');
  }
}