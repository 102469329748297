
import { Thread } from '../engine.service'

export interface Message<Type> {
    type: Type;
}

export type MessageHandler<T extends string, M extends Message<T>> = (
    message: M
  ) => void;

export enum EngineMessageType {
    InitialiseSimulator = "initialize-simulator",
    SimulatorInitialised = "simulator-initialised",
    StartSimulator = "start-simulator",
    SimulatorStarted = "simulator-started",
    SimulatorTest = "simulator-test",
    SimulatorLoadSimulation = "simulator-load-simulation",
    SimulatorStopSimulations = "simulator-stop-simulations",
    SimulationLoaded = "simulator-simulation-loaded",

    InitialiseRenderer = "initialise-renderer",
    RendererInitialised = "rederer-initialised",
    StartRenderer = "start-renderer",
    RendererStarted = "renderer-started",
    ResizeRenderer = "resize-renderer",
    ExitRenderer = "exit-renderer",
    RendererTest = "renderer-test",
    RendererStartAssetContainer = "renderer-start-asset-container",
    RendererStopAssetContainer = "renderer-stop-asset-container",
    RendererCreateFromPlugin = "renderer-create-from-plugin",
    RendererLoadRender = "renderer-load-render",
    RendererUserMessage = "renderer-user-message",
    RendererPointerLockChange = "renderer-pointer-lock-change",
    RendererPointerEvent = "renderer-pointer-event",
    RendererControllerEnabled = "renderer-controller-enabled",
    RendererControllerOptions = "renderer-controller-options",
}
  
export interface EngineMessage<T extends EngineMessageType = EngineMessageType> {
    type: T;
}

export interface InitialiseSimulatorMessage extends EngineMessage {
    type: EngineMessageType.InitialiseSimulator;
    rendererMessagePort?: MessagePort;
    mainToSimulationTripleBufferFlags: Uint8Array;
    simulationToRenderTripleBufferFlags: Uint8Array;
    simulationToMainTripleBufferFlags: Uint8Array;
    eventPort: any;
    eventPorts?: any;
}
  
export interface SimulatorInitialisedMessage extends EngineMessage {
    type: EngineMessageType.SimulatorInitialised;
}

export interface StartSimulatorMessage extends EngineMessage {
    type: EngineMessageType.StartSimulator;
}

export interface SimulatorStartedMessage extends EngineMessage {
    type: EngineMessageType.SimulatorStarted;
}

export interface SimulatorTestMessage extends EngineMessage {
    type: EngineMessageType.SimulatorTest;
    message: string;
}

export interface SimulatorLoadSimulationMessage extends EngineMessage {
    type: EngineMessageType.SimulatorLoadSimulation;
    pathRoot:string;
    scripts: string[];
    webpackChunkName: string;
}

export interface SimulatorStopSimulationsMessage extends EngineMessage {
    type: EngineMessageType.SimulatorStopSimulations;
}

export interface SimulationLoadedMessage extends EngineMessage {
    type: EngineMessageType.SimulationLoaded;
    pathRoot:string;
}

export interface RendererLoadRenderMessage extends EngineMessage {
    type: EngineMessageType.RendererLoadRender;
    pathRoot:string;
    scripts: string[];
    webpackChunkName: string;
}

export interface RendererUserMessage extends EngineMessage {
    type: EngineMessageType.RendererUserMessage;
    data:any;
}

export interface RendererPointerEvent extends EngineMessage {
    type: EngineMessageType.RendererPointerEvent;
    event:any;
}

export interface RendererControllerEnabled extends EngineMessage {
    type: EngineMessageType.RendererControllerEnabled;
    enabled:boolean;
    x: number;
    y: number;
}

export interface RendererControllerOptions extends EngineMessage {
    type: EngineMessageType.RendererControllerOptions;
    options:any;
}


export interface InitialiseRendererMessage extends EngineMessage {
    type: EngineMessageType.InitialiseRenderer;
    simulatorMessagePort?: MessagePort;
    canvas?: HTMLCanvasElement | OffscreenCanvas;
    eventPort: any;
    eventPorts?: any;
    ngZone?: any;
    simulationToRenderTripleBufferFlags: Uint8Array;
}

export interface RendererInitialisedMessage extends EngineMessage {
    type: EngineMessageType.RendererInitialised;
}

export interface StartRendererMessage extends EngineMessage {
    type: EngineMessageType.StartRenderer;
}

export interface RendererStartedMessage extends EngineMessage {
    type: EngineMessageType.RendererStarted;
}

export interface RendererStartAssetContainerMessage extends EngineMessage {
    type: EngineMessageType.RendererStartAssetContainer;
    assetContainerName: string;
}

export interface RendererStopAssetContainerMessage extends EngineMessage {
    type: EngineMessageType.RendererStopAssetContainer;
    assetContainerName: string;
}

export interface RendererTestMessage extends EngineMessage {
    type: EngineMessageType.RendererTest;
    message: string;
}

export interface ResizeRendererMessage extends EngineMessage {
    type: EngineMessageType.ResizeRenderer;
    width: number,
    height: number
}

export interface ExitRendererMessage extends EngineMessage {
    type: EngineMessageType.ExitRenderer;
    signout: boolean
}

export interface RendererCreateFromPlugin extends EngineMessage {
    type: EngineMessageType.RendererCreateFromPlugin;
    pluginClassName: string;
    pluginOptions: any
}

export interface RendererPointerLockChange extends EngineMessage {
    type: EngineMessageType.RendererPointerLockChange;
    isPointerLock: boolean;
}
// export class Messaging {

//     // public sendMessage<M extends Message<any>>(thread: Thread, message: M, transferList: Transferable[] = []) {
//     // }

//     public static registerMessageHandler<T extends string, M extends Message<T>>(
//         type: T,
//         handler: MessageHandler<T, M>
//       ) {
//           debugger
//         // let handlers = ctx.messageHandlers.get(type);
      
//         // if (!handlers) {
//         //   handlers = [];
//         //   ctx.messageHandlers.set(type, handlers);
//         // }
      
//         // handlers.push(handler as unknown as any);
      
//         // return () => {
//         //   const handlers = ctx.messageHandlers.get(type);
      
//         //   if (handlers) {
//         //     const index = handlers.indexOf(handler as unknown as any);
      
//         //     if (index !== -1) {
//         //       handlers.splice(index, 1);
//         //     }
//         //   }
//         // };
//       }
    
// }