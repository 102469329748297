/// <reference types="@angular/localize" />

import { enableProdMode, importProvidersFrom } from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient , provideHttpClient } from '@angular/common/http';

import { bootstrapApplication } from '@angular/platform-browser';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withHashLocation,
  withInMemoryScrolling,
  withDebugTracing,
  UrlSerializer
} from '@angular/router';

import { provideMarkdown } from 'ngx-markdown';

import { environment } from './environments/environment';
if (environment.production) {
  enableProdMode();
}

import { AppComponent } from './app/app.component';
import { routes } from './app/routes';

import {
  ScreenTrackingService,
  UserTrackingService,
  getAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';

import { CustomUrlSerializer } from './app/custom-url-serializer';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

bootstrapApplication(AppComponent, {
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    Location, {provide: LocationStrategy, useClass: PathLocationStrategy},
    provideAnimations(),
    provideHttpClient(),
    provideMarkdown({ loader: HttpClient }),
    provideRouter(routes, /*withHashLocation(),*/ inMemoryScrollingFeature /*,withDebugTracing()*/),
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAnalytics(() => getAnalytics()),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideFunctions(() => getFunctions()),
      provideMessaging(() => getMessaging()),
      providePerformance(() => getPerformance()),
      provideStorage(() => getStorage()),
    ]),
    ScreenTrackingService,
    UserTrackingService
  ],
});






