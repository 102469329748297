import { Component, EventEmitter, inject, ViewChild, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import {Event, RouterEvent, Router, NavigationEnd} from '@angular/router';

import { filter } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';

import { AuthService } from '../../../auth/services/auth.service';

import { EngineComponent } from '@ablex/metaverse/src/app/engine/engine.component';

@Component({
  selector: 'ablex-engine-manager',
  templateUrl: './engine-manager.component.html',
  standalone: true,
  imports: [
    EngineComponent,
    MatButtonModule,
    NgClass,
    NgIf
  ]
})
export class EngineManagerComponent {
  
  public authService: AuthService = inject(AuthService);

  @Output() navigationEndWithGamesSegment = new EventEmitter<boolean>(); 
  @Output() pointerLockChange = new EventEmitter<boolean>(); 
  @Output() rendererReady = new EventEmitter<boolean>(); 

  @ViewChild('engine') engine!:EngineComponent;

  public hasAR = false;
  public hasVR = false;

  public simulations: string[] = [];

  private _forceIsGame = false;

  private _gamesSegment = 'content:games/';
  private _ready = false;

  private _accessibility_extension = 0;

  constructor(public router: Router) {
    router.events.pipe(
       filter((e: Event | RouterEvent): e is RouterEvent => e instanceof RouterEvent)
    ).subscribe(this.onRouterEvent.bind(this));
  }

  public onRendererReady(){
    this.rendererReady.emit(true);
  }

  public onSimulatorReady(){
    this._ready = true;
    // Initial scene 
    this.updateFromUrl(location.href);       
  }

  public onSimulationLoaded(event: any){
    this.simulations.push(event.pathRoot);
  }

  private onRouterEvent(e: RouterEvent){
    if (e instanceof NavigationEnd ) {
      const includesGamesSegment = e.urlAfterRedirects.includes(this._gamesSegment);
      if(e.urlAfterRedirects.includes("/dashboard/") && 
        (!includesGamesSegment || e.urlAfterRedirects.includes("empty"))){
        // If logged and not a game screen, stop and hide the engine, disable point-event receiving
        // stopping handled by this.updateFromUrl(), pointer-event toggle handled in app.component.ts navigationEndWithGamesSegment subscription
        // So just need to do hiding here
        this.engine?.hide();
        this.navigationEndWithGamesSegment.emit(false);
        return;
      } else {
        this.engine?.show();
      }

      if(this._forceIsGame || includesGamesSegment){
        this.navigationEndWithGamesSegment.emit(true);
      }
      
      this.updateFromUrl(e.urlAfterRedirects);
    }
  }

  private updateFromUrl(url: string){
    if(!this._ready) return;

    this.hasAR = false;
    this.hasVR = false;

    this._forceIsGame = false;

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as string),
    }); 

    // @ts-ignore
    const accessibility_extension = params.accessibility_extension;
    if(accessibility_extension){
      this._accessibility_extension = parseInt(accessibility_extension);
    } else {
      this._accessibility_extension = 0;
    }

    if(url.includes(this._gamesSegment)){
      // Stop any existing simulations
      this.engine.stopSimulations();
      this.engine.setControllerEnabled(true);

      // Only enable enter AR/VR buttons for games
      this.hasAR = true;
      this.hasVR = true;

      const name = this.getContentNameFromUrl(url);
      this.loadSimulationByName(name);

    } else if(url.includes('dnfhlfhomlcdlemeoijnhcofcipcfelb') || this._accessibility_extension > 0){
      // Our accessibility extension
      this.engine.stopSimulations();
      this.engine.setControllerEnabled(true);
      document.body.style.cursor = "none";
      this.navigationEndWithGamesSegment.emit(true);
      this._forceIsGame = true;
      this.loadSimulationByName('accessibility_extension');

    } else if(url.includes('/public')){
      this.engine.stopSimulations();
      this.engine.setControllerEnabled(false);
      //this.loadSimulationByName('promo');

    } else {
       this.engine.stopSimulations();
       this.engine.setControllerEnabled(false);
       this.loadSimulationByName('empty');

    }
  }

  private getContentNameFromUrl(url: string): string {
    
    if(url.includes(this._gamesSegment)){
      let startOfGameSegment = url.indexOf(this._gamesSegment);
      startOfGameSegment += this._gamesSegment.length;
      let endIndex = url.indexOf('/', startOfGameSegment);
      if( endIndex == -1 ){
        endIndex = url.indexOf(')', startOfGameSegment);
      }
      return url.substring(startOfGameSegment, endIndex);
    }
    return "";
  }

  private loadSimulationByName(name: string){
    if(!name) return;

    // Load scene $name
    const webpackChunkName = `game_${name}`;
    const pathRoot = `/api/assets/games/${name}/`;
    const scripts = ['Simulation.js'];
    this.engine.loadSimulation(pathRoot, scripts, webpackChunkName);
  }

  public onExitRenderer(options: any){
    if(options.signout){

    } else {
      // Navigate to the dashboard settings panel
      this.router.navigate(['dashboard', 
        { 
          outlets: { 
            // toolbar: [
            //   'user', this.userData.uid
            // ],
            // banner: [
            //   'user', this.userData.uid
            // ],
            content: [
              'settings', this.authService.currentUser?.uid
            ]
          }
        }],
        {
          queryParams:{optionA:'value',optionB:'value'}
        }
      );
    }
  }

  public onPointerLockChanged(event: any){
    this.pointerLockChange.emit(event.isPointerLock);
  }

  public deactivateExtension(){
    this.engine.pointerEventPassThough();
    this.engine.stopSimulations();
    this.engine.setControllerEnabled(false);
    document.body.style.cursor = "auto";
  }
}
