import { EventEmitter, Injectable, NgZone } from '@angular/core';
// import { applyMixins } from './utils/mixins';

import {Deferred} from "ts-deferred";

// import {
//     IWebXRHitResult,
//     WebXRAnchorSystem,
//     WebXRDefaultExperience,
//     WebXRExperienceHelper,
//     WebXRFeaturesManager,
//     WebXRFeatureName,
//     WebXRHitTest,
//     WebXRImageTracking,
//     WebXRLayers,
//     WebXRLightEstimation,
//     WebXRSessionManager,
//     WebXRState,
//     WebXRCamera
// } from '@babylonjs/core/XR';

import {
    EngineMessageType,
    InitialiseRendererMessage,
    InitialiseSimulatorMessage,
    SimulatorInitialisedMessage,
    RendererControllerEnabled,
    RendererControllerOptions,
    RendererInitialisedMessage,
    RendererStartedMessage,
    ResizeRendererMessage,
    RendererPointerLockChange,
    ExitRendererMessage,
    Message,
    MessageHandler,
    StartSimulatorMessage,
    StartRendererMessage,
    SimulatorStartedMessage,
    SimulatorLoadSimulationMessage,
    SimulatorStopSimulationsMessage,
    SimulationLoadedMessage
} from './messaging/Messaging';

// import { Renderer } from './renderer/renderer';

export enum Thread {
    Engine = "engine",
    Simulator = "simulator",
    Renderer = "renderer",
}


@Injectable({ providedIn: 'root' })
export class EngineService {

    public simulatorReadyEvent = new EventEmitter<any>(); 
    public simulationLoadedEvent = new EventEmitter<any>(); 
    public rendererReadyEvent = new EventEmitter<any>(); 
    public exitRendererEvent = new EventEmitter<any>(); 
    public pointerLockChangedEvent = new EventEmitter<any>(); 

    public enableXR = false;  // true means renderer on main thread, set based on capability

    public mainToSimulationTripleBufferFlags!: Uint8Array;
    public simulationToRenderTripleBufferFlags!: Uint8Array;
    public simulationToMainTripleBufferFlags!: Uint8Array;

    public displayCanvas!: HTMLCanvasElement;

    // private _renderThread!: Worker | Renderer;
    private _renderThread!: Worker | any;
    private _simulatorThread: Worker;
    private _rendererReady: Deferred<string>;

    private _messageHandlers: Map<string, MessageHandler<string, Message<any>>[]> = new Map();

    private _enterFullscreenPointerlockBlock = false;

    private _mousemoveX = 0;
    private _mousemoveY = 0;

    private _accessibility_extension = 0;

    public constructor(private ngZone: NgZone) {

        this._rendererReady = new Deferred<string>();

        if(globalThis.crossOriginIsolated){
            console.log('Window is crossOriginIsolated, SAB\'s available');
            this.mainToSimulationTripleBufferFlags = new Uint8Array(new SharedArrayBuffer(Uint8Array.BYTES_PER_ELEMENT)).fill(0x6);
            this.simulationToRenderTripleBufferFlags = new Uint8Array(new SharedArrayBuffer(Uint8Array.BYTES_PER_ELEMENT)).fill(0x6);
            this.simulationToMainTripleBufferFlags = new Uint8Array(new SharedArrayBuffer(Uint8Array.BYTES_PER_ELEMENT)).fill(0x6);
            
        } else {
            if(location.protocol !== "chrome-extension:"){
                console.warn('Cant initialise SABs because not crossOriginIsolated');
            }
        }

        // this._simulatorThread = new Worker(new URL('./simulator/simulator.worker', import.meta.url), {type: "module"});
        this._simulatorThread = new Worker(new URL('./simulator/simulator.worker', import.meta.url));

        // Receive messages from the simulation thread
        this._simulatorThread.onmessage = (evt: MessageEvent<any>) => this.onMessage(evt);

        // Setup message handlers
        this.registerMessageHandler(EngineMessageType.SimulatorInitialised, (data: SimulatorInitialisedMessage) => this.onSimulatorInitialised(data));
        this.registerMessageHandler(EngineMessageType.SimulatorStarted, (data: SimulatorStartedMessage) => this.onSimulatorStarted(data));
        this.registerMessageHandler(EngineMessageType.SimulationLoaded, (data: SimulationLoadedMessage) => this.onSimulationLoaded(data));

        this.registerMessageHandler(EngineMessageType.RendererInitialised, (data: RendererInitialisedMessage) => this.onRendererInitialised(data));
        this.registerMessageHandler(EngineMessageType.RendererStarted, (data: RendererStartedMessage) => this.onRendererStarted(data));
        this.registerMessageHandler(EngineMessageType.ExitRenderer, (data: ExitRendererMessage) => this.onExitRenderer(data));
    }

    public onMessage<M extends Message<any>>(evt: MessageEvent<any> | M) {
        let data = evt instanceof MessageEvent ? evt.data : evt;
        const handlers = this._messageHandlers.get(data.type);
        if (handlers) {
            for (let i = 0; i < handlers.length; i++) {
                handlers[i](data);
            }
        }
    }

    private registerMessageHandler<T extends string, M extends Message<T>>(type: T, handler: MessageHandler<T, M>) {
        // Get all the handlers for the message type
        let handlers = this._messageHandlers.get(type);

        // Set up a new array of handlers for this type if none exists
        if (!handlers) {
            handlers = [];
            this._messageHandlers.set(type, handlers);
        }

        // Add handler to array of handlers for this type
        handlers.push(handler as unknown as any);
    }

    public async createScene(canvas: HTMLCanvasElement): Promise<void> {

        this.displayCanvas = canvas;

        this.displayCanvas.style.visibility = "hidden";

        if (navigator.xr && location.protocol !== "chrome-extension:") {
            try{
                const immersiveARSupported = await navigator.xr.isSessionSupported("immersive-ar");
                let immersiveVRSupported = false;
                if(!immersiveARSupported){
                    immersiveVRSupported = await navigator.xr.isSessionSupported("immersive-vr");
                }
                
                // We are not interested in inline support if we dont have R or VR
                // const inlineSupported = navigator.xr.isSessionSupported("inline");
                
                if (immersiveARSupported || immersiveVRSupported) {
                    this.enableXR = true;
                }
            }catch(e){
                console.warn(e);
            }
        }

        const simulatorAndRenderMessageChannel = new MessageChannel();

        document.addEventListener("pointerlockchange", (event) => {
            let isPointerLock = false;
            if(document.pointerLockElement === canvas){
                isPointerLock = true;
            }

            console.log("EngineService pointerlockchange " + isPointerLock);

            this.sendMessageToRenderer({
                type: EngineMessageType.RendererPointerLockChange,
                isPointerLock: isPointerLock
            } as RendererPointerLockChange);

            this.pointerLockChangedEvent.emit({isPointerLock});

        }, false);

        // Add handle fullscreen on the canvas element's click event 
        // before it is possibly transferred offscreen
        canvas.addEventListener("click", async (event: MouseEvent) => {
            if(this._enterFullscreenPointerlockBlock) return;
            if(document.pointerLockElement !== canvas){
                
                //const element = document.elementsFromPoint(event.clientX, event.clientY);
                
                await canvas.requestPointerLock();

                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop as string),
                  }); 
              
                  // @ts-ignore
                  const accessibility_extension = params.accessibility_extension;
                  if(accessibility_extension){
                    this._accessibility_extension = parseInt(accessibility_extension);
                  } else {
                    this._accessibility_extension = 0;
                  }

                if(globalThis.location.protocol !== "chrome-extension:" && !(this._accessibility_extension > 0)){
                    //canvas.requestFullscreen();
                }
                
            } else {
                //console.log("Already in pointerlock, dont request pointerlock or fullscreen again"); 
            }
        });

        this.enableXR = true;
        // todo if using inspector force main thread ??

        if (!this.enableXR && this.supportsOffscreenCanvas(canvas)) {
            console.log("Renderer on worker thread");

            if (typeof Worker !== 'undefined') {
                this._renderThread = new Worker(new URL('./renderer/renderer.worker', import.meta.url));

                // Receive messages from the renderer thread
                this._renderThread.onmessage = (evt: MessageEvent<any>) => this.onMessage(evt);

                canvas.width = canvas.clientWidth;
                canvas.height = canvas.clientHeight;


                
                // @ts-ignore
                const eventPort = canvas.createEventPort();
                try {
                    const offscreenCanvas = (canvas as any).transferControlToOffscreen();
                    this.sendMessageToRenderer({
                        type: EngineMessageType.InitialiseRenderer,
                        canvas: offscreenCanvas,
                        eventPort: eventPort,
                        simulatorMessagePort: simulatorAndRenderMessageChannel.port2,
                        simulationToRenderTripleBufferFlags: this.simulationToRenderTripleBufferFlags
                    } as InitialiseRendererMessage,
                        [offscreenCanvas, eventPort, simulatorAndRenderMessageChannel.port2]
                    );
                } catch (e) {
                    this.sendMessageToRenderer({
                        type: EngineMessageType.InitialiseRenderer,
                        eventPort: eventPort,
                        simulationToRenderTripleBufferFlags: this.simulationToRenderTripleBufferFlags
                    } as InitialiseRendererMessage,
                        [eventPort]
                    );
                }

            } else {
                console.error("Web workers are not supported in this environment.");
            }

        } else {
            console.log("Renderer on main thread");

            const rendererModule = await import("./renderer/renderer");
            // .then((Renderer:any)=>{
            //     debugger
            //     this._renderThread = new Renderer();
            // });


            this._renderThread = new rendererModule.Renderer();

            // Receive messages from the renderer thread. 
            // When the both the engine and Renderer are on the main thread, replace Renderer's sendMessageToEngine
            // to forward the message directly to the engine's onMessage handler
            this._renderThread.sendMessageToEngine = <M extends Message<any>>(evt: MessageEvent<any> | M) => this.onMessage(evt);

            this.sendMessageToRenderer({
                type: EngineMessageType.InitialiseRenderer,
                canvas: canvas,
                simulatorMessagePort: simulatorAndRenderMessageChannel.port2,
                ngZone: this.ngZone,
                simulationToRenderTripleBufferFlags: this.simulationToRenderTripleBufferFlags
            } as InitialiseRendererMessage,
                [simulatorAndRenderMessageChannel.port2]
            );
        }

        window.addEventListener("resize", () => {
            this.resize();
        });

        document.addEventListener("mousemove", (e) => {
            this._mousemoveX = e.pageX;
            this._mousemoveY = e.pageY;
        });

        // Dont send canvas element events directly to simulator, only send them to the render
        // @ts-ignore
        //const eventPort = canvas.createEventPort();
        this.sendMessageToSimulator(
            {
                type: EngineMessageType.InitialiseSimulator,
                rendererMessagePort: simulatorAndRenderMessageChannel.port1,
                mainToSimulationTripleBufferFlags: this.mainToSimulationTripleBufferFlags,
                simulationToRenderTripleBufferFlags: this.simulationToRenderTripleBufferFlags,
                simulationToMainTripleBufferFlags: this.simulationToMainTripleBufferFlags,
                //eventPort: eventPort
            } as InitialiseSimulatorMessage,
            [simulatorAndRenderMessageChannel.port1/*, eventPort*/]
        );
    }

    public loadSimulation(pathRoot:string, scripts:string[], webpackChunkName: string){
        this.sendMessageToSimulator({ 
            type: EngineMessageType.SimulatorLoadSimulation,
            pathRoot,
            scripts,
            webpackChunkName
        } as SimulatorLoadSimulationMessage);
    }

    public stopSimulations(){
        this.sendMessageToSimulator({ 
            type: EngineMessageType.SimulatorStopSimulations,
        } as SimulatorStopSimulationsMessage);
    }

    private supportsOffscreenCanvas(canvas: HTMLCanvasElement): boolean {
        if ("OffscreenCanvas" in window && "transferControlToOffscreen" in canvas) {
            return true;
        }
        return false;
    }

    private sendMessageToSimulator<M extends Message<any>>(message: M, transferList: Transferable[] = []) {
        this._simulatorThread?.postMessage(message, transferList);
    }

    private sendMessageToRenderer<M extends Message<any>>(message: M, transferList: Transferable[] = []) {
        this._renderThread?.postMessage(message, transferList);
    }

    private onSimulatorInitialised({ }: SimulatorInitialisedMessage) {
        this.sendMessageToSimulator({ type: EngineMessageType.StartSimulator } as StartSimulatorMessage);
    }

    private async onSimulatorStarted({ }: SimulatorStartedMessage) {
        // Wait for the renderer to complete startup before we say the simulator is ready
        await this._rendererReady.promise;
        this.simulatorReadyEvent.emit({});
    }

    private onRendererInitialised({ }: RendererInitialisedMessage) {
        this.sendMessageToRenderer({ type: EngineMessageType.StartRenderer } as StartRendererMessage);
    }

    private onRendererStarted({ }: RendererStartedMessage) {
        this._rendererReady.resolve("Engine Service: Renderer started");
        this.displayCanvas.style.visibility = "visible";
        this.rendererReadyEvent.emit({});
    }

    private onExitRenderer({ signout }: ExitRendererMessage) {
        // Block the click causing a request for fullscreen or pointerlock
        this._enterFullscreenPointerlockBlock = true;
        setTimeout(() => {this._enterFullscreenPointerlockBlock = false;}, 1000);

        if(document.pointerLockElement === this.displayCanvas){
            document.exitPointerLock();
        }
        if (document.fullscreenElement){
            document.exitFullscreen();
        }
        this.exitRendererEvent.emit({signout})
    }
    
    private onSimulationLoaded({ pathRoot }: SimulationLoadedMessage) {
        this.simulationLoadedEvent.emit({pathRoot})
    }

    public setControllerEnabled(value: boolean) {
        this.sendMessageToRenderer({ 
            type: EngineMessageType.RendererControllerEnabled,
            enabled: value,
            x: this._mousemoveX,
            y: this._mousemoveY
        } as RendererControllerEnabled);
    }

    public setControllerOptions(options: any) {
        this.sendMessageToRenderer({ 
            type: EngineMessageType.RendererControllerOptions,
            options: options
        } as RendererControllerOptions);
    }

    public show(){
        this.displayCanvas.style.display = "";
        this.resize();
    }

    public hide(){
        this.displayCanvas.style.display = "none";
    }

    private resize(){
        this.sendMessageToRenderer({
            type: EngineMessageType.ResizeRenderer,
            width: this.displayCanvas.clientWidth,
            height: this.displayCanvas.clientHeight
        } as ResizeRendererMessage);
    }
}

// export interface EngineService extends Messaging {}
// applyMixins(EngineService, [Messaging]);