import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import {
    applyActionCode,
    Auth,
    authState,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
    user,
    User
} from '@angular/fire/auth';
import {
    doc,
    collection,
    CollectionReference,
    Firestore,
    DocumentReference,
    setDoc
} from '@angular/fire/firestore';

import { UserProfile } from './UserProfile';
import { UserService } from './user.service';

type ApiMethod = "GET" | "POST" | "PUT" | "DELETE";

//  TODO: https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public afAuth: Auth = inject(Auth);
  public authState$ = authState(this.afAuth);
  public user$ = user(this.afAuth);

  public userService: UserService = inject(UserService);


  userData: any; // Save logged in user data

  constructor(
    public afs: Firestore, // Inject Firestore service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.onAuthStateChanged((user) => {
        if (user) {
            // debugger
            this.userData = user;
            localStorage.setItem('user', JSON.stringify(this.userData));
            JSON.parse(localStorage.getItem('user')!);
        } else {
            localStorage.setItem('user', 'null');
            JSON.parse(localStorage.getItem('user')!);
        }
    });
  }

  // Sign in with email/password
  signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password)
      .then( async (userCredential) => {
        this.userService.updateUserProfileFromFirebaseAuthUser(userCredential.user);
        this.goToUserDashboard();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }

  // Sign up with email/password
  SignUp(email: string, password: string, displayName: string) {
    return createUserWithEmailAndPassword(this.afAuth, email, password)
      .then(async (result) => {
        await updateProfile(result.user, {displayName: displayName});
        this.SendVerificationMail();
        this.userService.updateUserProfileFromFirebaseAuthUser(result.user);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    if (!this.afAuth.currentUser) return;

    return sendEmailVerification(this.afAuth.currentUser)
      .then(() => {
        this.router.navigate(['/auth/verify-email']);
      });
  }

  sendSignInLinkMail() {
    if (!this.afAuth.currentUser) return;

    // return sendSignInLinkToEmail(this.afAuth.currentUser)
    //   .then(() => {
    //     this.router.navigate(['/auth/verify-email']);
    //   });
  }

  

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    // return this.afAuth
    //   .sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   })
    //   .catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Returns true when user is looged in and email is verified
  async isLoggedIn() {
    await this.afAuth.authStateReady();

    return this.afAuth.currentUser !== null ? true : false;

    // const user = JSON.parse(localStorage.getItem('user')!);
    // return user !== null && user.emailVerified !== false ? true : false;
  }



  signOut() {
    return signOut(this.afAuth).then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['/']);
    });
  }

  public applyActionCode(actionCode:string): Promise<void>{
    return applyActionCode(this.afAuth, actionCode);
  }

  private async goToUserDashboard(){
    const params = new URLSearchParams(location.search);
    const uid = this.currentUser ? this.currentUser.uid : '';
    let custom_token = undefined;

    // todo for now always go to the users setings rather than a game starting point
    // let content = [
    //   'games', 'empty' 
    // ]
    const content = [
      'settings', uid
    ]

    if(params.has('via_extension') === true){
      custom_token = await this.getCustomToken();
    }
    //await this.router.navigate(['dashboard', 'user', this.userData.uid  ]);
    await this.router.navigate(['dashboard', 
      { 
        outlets: { 
          // toolbar: [
          //   'user', uid
          // ],
          banner: [
            'user', uid
          ],
          content: content
        }
      }
      ],
      {
        queryParams:{custom_token: custom_token},
        queryParamsHandling: 'merge'
      }
    );
  }

  public async getCustomToken(){
      // get custom jwt token from server
      const token = await this.getCustomTokenFromServer();
    return token;
  }

  public get currentUser(){
    return this.afAuth.currentUser;
  }

  async getCustomTokenFromServer() {
    const customToken = (await this.callApi("/api/auth/createCustomToken", "GET")).data
      .customToken as string;
    return customToken;
  }

  private  async callApi(
    url: string,
    method: ApiMethod,
    payload?: any
  ): Promise<{ data?: any; error?: unknown }> {
    let res = { data: "", error: "" };
    try {
      const token = (await this.afAuth.currentUser?.getIdToken(true)) as string;
  
      res = await this.fetcher(url, method, token, payload);
    } catch (e: any) {
      res.error = e.message;
      console.error("Error: ", e.message);
    }
  
    return res;
  }

  private async fetcher (
    url: string,
    method: ApiMethod,
    token: string,
    payload?: string
  ): Promise<any> {
    const requestHeaders = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", token);
  
    const res = await fetch(url, {
      body: payload ? JSON.stringify(payload) : undefined,
      headers: requestHeaders,
      method,
    });
  
    let resobj = res;
    try {
      resobj = await res.json();
    } catch (e) {
      throw new Error("Unexpected issue. Please try again.");
      // }
    }
    return resobj;
  }
  
}