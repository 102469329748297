import {inject} from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { AuthService } from './services/auth.service';

export const authGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn()
    .then((isLoggedIn)=>{
      if(isLoggedIn){
        return true;
      } else {
        // Redirect to the login page
        return router.parseUrl('/auth/signin');
      }
    });
};

export const unAuthedGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);

  return authService.isLoggedIn()
    .then(async (isLoggedIn)=>{
      if(isLoggedIn){
        // If we are alerady logged in, check if we need to generate a token for the browser extension
        const params = new URLSearchParams(location.search);
        if(params.has('via_extension') === true){
          // get a custom token for the browser extension to authorise itself with
          return await authService.getCustomToken();
        } else {
          return "";
        }
      } else {
        return true;
      }
    })
    .then((result)=>{
      if( result === true){
        // "allowed" to proceed, onward to login screen
        return true;
        
      } else {
        // Already logged in. 
        // Redirect to the dashboard page,
        // with extras if coming via browser extension
        let navigationExtras: NavigationExtras | undefined = undefined;
        if(result){
          const queryParams =  { custom_token: result };
          const params = new URLSearchParams(location.search);
          if(params.has('tabId') === true){
            Object.assign(queryParams, {tabId : params.get('tabId')});
          }
          
          navigationExtras = {
            queryParams: queryParams//,
            // fragment: 'anchor'
          };
        }
      
        // todo for now always go to the users setings rather than a game starting point
        // let content = [
        //   'games', 'empty' 
        // ]
        const content = [
          'settings', authService.currentUser?.uid
        ]

        return router.createUrlTree(['dashboard', 
        { 
          outlets: { 
            // toolbar: [
            //   'user',  authService.currentUser?.uid
            // ],
            banner: [
              'user',  authService.currentUser?.uid
            ],
            content: content
          }
        }
        ], navigationExtras);
      }
    });
};