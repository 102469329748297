import { inject, Injectable, OnDestroy } from '@angular/core';

import {
  addDoc,
  doc,
  collection,
  collectionData,
  CollectionReference,
  Firestore,
  DocumentData,
  DocumentReference,
  setDoc,
  onSnapshot, 
  DocumentSnapshot,
  Unsubscribe
} from '@angular/fire/firestore';

import {
  User
} from '@angular/fire/auth';

import { BehaviorSubject , Observable, ReplaySubject, Subscription} from 'rxjs';

import { UserProfile } from './UserProfile';
import { UserExtensionSettings } from './UserExtensionSettings';

export enum UpdateSource{
  Local,
  Server
}

export interface UserProfileUpdate {
  source: UpdateSource,
  userProfile: UserProfile
}


@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {

  // todo what does this get, looks like all users!! need rules setup!!
  public users$!: Observable<UserProfile[]>;

  private firestore: Firestore = inject(Firestore);

  private _userProfileCollection: CollectionReference;

  private _userUnsubscribe = new Map<string, Unsubscribe>();

  private _userProfileObservables = new Map<string, ReplaySubject<UserProfileUpdate>>();

  constructor() {

    this._userProfileCollection = collection(this.firestore, "users");

    // get documents (data) from the collection using collectionData
    // todo dont do this till we have rules to limit the results to those permisible
    //this.users$ = collectionData(this._userProfileCollection) as Observable<UserProfile[]>;
  }


  // addUserProfile(displayName: string) {
  //   if (!displayName) return;

  //   addDoc(this._userProfileCollection, <UserProfile> { displayName }).then((documentReference: DocumentReference) => {
  //       // the documentReference provides access to the newly created document
  //   });
  // }

  // async getUserProfile(uid: string){
  //   const userRef = await doc(this._userProfileCollection, uid);

  //   const usersSettingsCollection = collection(this.firestore, `users/${user.uid}/settings`);
  //   return 
  // }

  // updateUserSettings(user: UserProfile, settings: UserExtensionSettings){
    
  //   // const userRef = this.getUserProfile(user.uid);

  //   const usersSettingsCollection = collection(this.firestore, `users/${user.uid}/settings`);

  //   const settingsRef = settings.uid ? doc(usersSettingsCollection, settings.uid) : doc(usersSettingsCollection);

  //   const setOptions = {
  //     merge: true
  //   }

  //   setDoc(settingsRef, settings, setOptions);
  // }

  updateUserProfileFromFirebaseAuthUser(user: User) {
    const userRef: DocumentReference = doc(this.firestore, `users/${user.uid}`);

    const userProfile: UserProfile = {
      creationTime: user.metadata.creationTime,
      lastSignInTime: user.metadata.lastSignInTime,
      displayName: user.displayName,
      email: user.email,
      emailVerified: user.emailVerified,
      isAnonymous: user.isAnonymous,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      providerId: user.providerId,
      tenantId: user.tenantId,
      uid: user.uid
    };

    const setOptions = {
        merge: true
    }

    setDoc(userRef, userProfile, setOptions);
  }

  updateUserProfile(userProfile: UserProfile) {
    const userRef: DocumentReference = doc(this.firestore, `users/${userProfile.uid}`);

    const setOptions = {
        merge: true
    }

    setDoc(userRef, userProfile, setOptions);
  }

  // todo, for therapist we want to subscribe to all users in a query result
  // for a patient & therapist just subscribe to self
  getUserProfileObservable(uid: string){

    if(this._userProfileObservables.has(uid)) return this._userProfileObservables.get(uid);

    const subject = new ReplaySubject<UserProfileUpdate>(1);
    onSnapshot(doc(this.firestore, "users", uid), 
      (snapshot => {
        subject.next({
          source: snapshot.metadata.hasPendingWrites ? UpdateSource.Local : UpdateSource.Server,
          userProfile: snapshot.data() as UserProfile
        });
      }),
      (error => subject.error(error.message))
    );
    this._userProfileObservables.set(uid, subject);

    return subject;

    // const observable = new Observable<UserProfile>(observer => {
    //   // Returning the teardown function from the "subscribe function" passed to the constructor
    //   // ie the unsubscribe function from onSnapshot, will get called when the observable is disposed
    //   return onSnapshot(doc(this.firestore, "users", uid), 
    //     (snapshot => subject.next(snapshot.data() as UserProfile)),
    //     (error => subject.error(error.message))
    //   );
    // });

    // this._userProfileObservables.set(uid, observable);
    // return observable;
    
    


    // const unsubscribeFunction = onSnapshot(doc(this.firestore, "users", uid), (doc: DocumentSnapshot<DocumentData, DocumentData>) => {
    //   const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    //   console.log(source, " data: ", doc.data());
    // });
    // this._userUnsubscribe.set(uid, unsubscribeFunction);
  }

  ngOnDestroy() {
    this.userProfileUnsubscribeAll();
  }

  private userProfileUnsubscribe(uid: string){
    this._userUnsubscribe.get(uid)?.();
  }

  private userProfileUnsubscribeAll(){
    for (const unsubscribeFunction of this._userUnsubscribe.values()) {
      unsubscribeFunction();
    }
    this._userUnsubscribe.clear();
  }
}