import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { EngineService } from './engine.service';

@Component({
  selector: 'app-engine',
  templateUrl: './engine.component.html',
  styleUrls:['./engine.component.scss'],
  standalone: true,
  imports: []
})
export class EngineComponent implements AfterViewInit {

  @Output() simulatorReady = new EventEmitter<any>(); 
  @Output() simulationLoaded = new EventEmitter<any>();
  @Output() rendererReady = new EventEmitter<any>(); 
  @Output() exitRenderer = new EventEmitter<any>(); 
  @Output() pointerLockChanged = new EventEmitter<any>(); 
  

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas!: ElementRef<HTMLCanvasElement>;

  public constructor(private engineService: EngineService) {
    this.engineService.simulatorReadyEvent.subscribe(_ => {this.simulatorReady.emit({})});
    this.engineService.simulationLoadedEvent.subscribe(_ => {this.simulationLoaded.emit(_)});
    this.engineService.rendererReadyEvent.subscribe(_ => {this.rendererReady.emit({})});
    this.engineService.exitRendererEvent.subscribe(_ => {this.exitRenderer.emit(_)});
    this.engineService.pointerLockChangedEvent.subscribe(_ => {this.pointerLockChanged.emit(_)});
  }

  public async ngAfterViewInit(){
    await this.engineService.createScene(this.rendererCanvas.nativeElement);
  }

  public loadSimulation(pathRoot: string, scripts:string[], webpackChunkName: string){
    this.engineService.loadSimulation(pathRoot, scripts, webpackChunkName);
  }

  public stopSimulations(){
    this.engineService.stopSimulations();
  }

  public setControllerEnabled(value: boolean){
    this.engineService.setControllerEnabled(value);
  }

  public setControllerOptions(options: any){
    this.engineService.setControllerOptions(options);
  }
  
  public hide(){
    this.engineService.hide();
  }

  public show(){
    this.engineService.show();
  }

  public pointerEventPassThough(){
    this.engineService.displayCanvas.style.pointerEvents = "none";
  }
}